@import '_variables.scss';

.home-grid {
  margin:18px;
  width: calc(100vw - 250px - 18px - 18px);// for IE11 fix
  min-width: calc(950px - 250px);
}

.home-grid-cell {
  text-align:center;
  background-color: #FFFFFF;
  margin:6px;
  padding:24px;
  height: 270px;
  min-width:160px;
  &:hover {
    background-color: #EFEFEF;
    transition: background-color .5s;
  }
  .home-gird-icon {
    font-size:55px;
    color:$kp-font-primary;
    margin:10px;
  }

  .home-grid-title {
    color:$kp-font-primary;
  }

  .home-grid-description {
    color: #999999;
    margin: 24px 0px;
  }
}

