/* =============Not found ================*/
@import '_variables.scss';


#general-alert-content {
  background-color: #f5f5f5;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 235px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .explain-box {
    width: 450px;
    background-color: #ebebeb;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .explain_box_left .explain-box-left {
      .icon {
        font-size: $gutter-large * 2;
        margin-right: $gutter-small;
        color: $text-color;
      }
      margin-right:$gutter-large;
    }
    .explain_box_right {
      margin-left: $gutter-small;
      color: #777777;
      h3 {
        font-weight: bold;
        color: #777777;
      }
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-top: $gutter-small;;
    }
    .icon{
      font-size: $gutter-large * 2;
      margin-right: $gutter-small;
      color: $text-color-dark;
    }
  }
}
//モジュール用
.general-alert-content-module {
  height: 100% !important;
}



#not-found-404 {
  background-color: #f5f5f5;
  display: flex;
  overflow: hidden;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .explain-box {
    width: 600px;
    background-color: #ebebeb;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    .explain_box_left {
      .not-found-icon {
        font-size: $gutter-large * 2;
        margin-right: $gutter-small;
        color: $text-color;
      }
    }
    .explain_box_right {
      margin-left: $gutter-small;
      color: #777777;
      h3 {
        font-weight: bold;
        color: #777777;
      }
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-top: $gutter-small;;
    }
    .not-found-icon {
      font-size: $gutter-large * 2;
      margin-right: $gutter-small;
      color: $text-color-dark;
    }
  }
}


#no-content,#alert-content {
  background-color: #f5f5f5;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 235px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .explain-box {
    width: 550px;
    background-color: #fafafa;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .explain-box-left {
      .no-content-icon {
        font-size: $gutter-large * 2;
        margin: 10px;
        color: #999999;
      }
      margin-right:$gutter-large;
    }
    .explain-box-right {
      margin-left: $gutter-small;
      color: #777777;
      h3 {
        font-weight: bold;
        color: #777777;
      }
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-top: $gutter-small;;
    }
    .no-content-icon,.alert-icon{
      font-size: $gutter-large * 2;
      margin-right: $gutter-small;
      color: #555555;
    }
  }
}

#redirect {
  background-color: #f5f5f5;
  display: flex;
  overflow: hidden;
  height: calc(100vh);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .explain-box {
    width: 550px;
    background-color: #ebebeb;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .explain_box_left {
      .redirect-icon {
        font-size: $gutter-large * 2;
        margin-right: $gutter-small;
        color: $text-color;
      }
      margin-right:$gutter-large;
    }
    .explain_box_right {
      margin-left: $gutter-small;
      color: #777777;
      h3 {
        font-weight: bold;
        color: #777777;
      }
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-top: $gutter-small;;
    }
    .redirect-icon {
      font-size: $gutter-large * 2;
      margin-right: $gutter-small;
      color: $text-color-dark;
    }
  }
}



#coming-soon {
  background-color: #f5f5f5;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 286px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .explain-box {
    width: 370px;
    background-color: #ebebeb;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .explain_box_left {
      margin-right:$gutter-large;
    }
    .explain_box_right {
      margin-left: $gutter-small;
      color: #777777;
      h3 {
        font-weight: bold;
        color: #777777;
      }
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-top: $gutter-small;;
    }
    .infomation-icon {
      font-size: $gutter-large * 2;
      margin-right: $gutter-small;
      color: $text-color;
    }
  }
}
