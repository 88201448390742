@import './variables';
@import './ModalCommon.scss';

.user-notification-modal {
    @extend .common-modal;

    .wrapper {

    }

    .ant-tabs-tab {
        text-align: left !important;
        padding-left: 0px !important;
    }

    .ant-tabs-nav-wrap {
        padding-left: 0px;
    }

    .ant-modal-body {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }


}

.module-notification-modal-title {
    display: flex;
    justify-content: space-between;
    .is-good-icon-wrapper {
        display:flex;
        justify-content: flex-end;
    };
    .spanned-content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6; /* 制限したい行数が3の場合 */
        overflow: hidden;
        margin:10px 0;
    }

}

.meta-flex {
    display: flex;
    justify-content: space-between;
}