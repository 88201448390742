@import './variables';


.module-schedule-tabs{
  height: 100%;
  .ant-tabs-content-holder {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
    }
  }
}


.schedule_wrapper {
  .schedule_header_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .schedule_type_wrapper {
      width: 60px;
      height: 50px;
      margin-right: $gutter-small;
      .cancel {
        border-radius: 8px;
        height: 100%;
        background: #FFD6D6;
        color: #D41418;
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .extra {
        border-radius: 8px;
        height: 100%;
        background: #D4D4E8;
        color: #242462;
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .change {
        border-radius: 8px;
        height: 100%;
        background: #D3E8D9;
        color: #21813D;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .schedule_label_wrapper {
      width: 100%;
      flex-direction: column;
      .schedule_date {
        color: $kp-font-primary
      }
      .schedule_label_title {
        font-size: 12pt;
      }
    }
  }
  .schedule_body_wrapper {
    .schedule_body_list {
      list-style-type: none;
      padding: $gutter-small;
      li {
        display: flex;
      }
      li:nth-child(even){
        background:#F1F1F1;
      }
      .header{
        width: 30%
      }
      .body {
        width: 70%
      }
    }
  }
}