@import './variables';



.module-header-separate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .button-pin {
    font-size: 20px;
    margin-left: $gutter-small;
    margin-right: 48px;
    color: white;
    &.pin {
    }
    &.unpin {
      color: $kp-mono-primary-light;
    }
  }
}


.module-footer-separate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.like-button {
  display: flex;
  flex-direction: row-reverse;
  button {
    &.like {
      background-color: $kp-primary;
      color: $white;
      border-color : $kp-primary;
      .anticon-like {
        color: $kp-primary;
      }
    }
    &.unlike {
      color: $kp-mono-primary-light;
      border-color : $kp-mono-primary-light;
      .anticon-like {
        color: $kp-mono-primary-light;
      }
    }

  }
}

.button-pin {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $kp-font-primary;
  .pin {
    margin-left: $gutter-small;
    margin-right: $gutter-small;
    font-size: 20px;
  }
}



.is-good-icon {
  align-items: flex-end;
  &.like {
    color: $kp-primary;
  }
  &.unlike {
    color: $kp-mono-primary-light;
  }
}