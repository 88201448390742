@import '_variables.scss';

.question_form {
  background: $questionnaire-form-bg;
  padding: $gutter;
  margin-bottom: $gutter-small;
  border: solid 2px white;

  .submit-container{
    width: 200px;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      min-width: 80px;
      margin-left: $gutter;
    }
    span {
      cursor: pointer;
    }
  }
}

.answer-result {
  display: flex;
  .answer-result-choice {
    width: 80%;
    display: flex;
    /*align-items: center;*/
    line-height: 175%;
    flex-direction: column;
    .aaaa {
      width: 100%;
      margin-bottom: 1em;
    }
  }
  .chart-box {
    max-width: 300px;
    display: flex;
    align-items: center;
  }
  .answer-result-choice-right {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

//円グラフ
path.recharts-sector {
  stroke: #eef3f5;
}

//棒グラフ
path.recharts-rectangle {
  fill: #AACF52;
}

.question-control-wrapper {
  display: flex;
  padding-bottom: $gutter-small;

  .question-title {
    width:100%;
    font-size: 14px;
    font-weight: bold;
    flex-basis: auto;
    padding-left: 3px;
  }

  .question-shrink-button {
    width: 70px;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }

}


.question-delete-button-wrapper{
  text-align: right;
}

.question-control-button {
  cursor: pointer;
  margin: 0 2px;
  font-size: 20px;
  :hover {
    opacity: 0.5;
  }
  :first-child {
    margin-left: 0;
  }
}

.question-control-button-mini {
  @extend .question-control-button;
  font-size: 14px;
}

.question-control-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 18px;
  transition: all .3s;
  margin-left: 10px;
}
.dynamic-delete-button:hover {
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.item-label-header {
  //border-left:5px solid $kp-mono-primary;
  //border-top:1px solid $kp-mono-primary;
  padding-left: 0.5em;
  @media (max-width: 768px) {
    line-height: 32px;
  }
  background: $kp-mono-primary;
  color: #fff;
}

.item-label-header_gray {
  padding-left: 1em;
  background: $kp-mono-primary;
  color: #fff;
}

.item-label-date {
  //border-top:1px solid $kp-mono-primary;
  padding-left: 1em;
  @media (max-width: 768px) {
    border:0;
  }
}

.fork-box {
  display:flex;
  flex-direction: row;
  .fork-question-header {
    width: 120px;
    text-align: left;
    background: lighten($kp-mono-primary, 10%);

    color: #fff;
    margin-bottom: 6px;
    padding: 4px 6px;
  }
  .fork-question-date {
    margin-left: 10px;
    margin-bottom: 6px;
    padding: 4px 6px;
  }
}
