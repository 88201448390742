@import '_variables.scss';

footer{
  display: flex;
  color: $kp-mono-secondary;
  background-color: $footer-bg;
  height: 105px;
  padding: 25px 33px;
  font-weight: 100;

  .footer-logo {
    width: 150px;
    height: 50px;
    display: block;
    margin-right: 20px;
  }

  .footer-main {
    flex-grow: 1;
    font-size: 11px;
    min-height: 50px;
    margin-left: $gutter;
  }

  .footer-copyright{
    text-align: right;
    align-self: flex-end;
    font-size: 11px;
  }

}
