//変数定義用sass

$kp-primary: #313198 !default;
$kp-primary-light: #675bca !default;
$kp-primary-dark: #000b69 !default;

$kp-secondary: #d70000 !default;
$kp-secondary-light: #ff5131 !default;
$kp-secondary-dark: #9d0000 !default;

$kp-mono-primary: #282828 !default;
$kp-mono-primary-light: #505050 !default;
$kp-mono-primary-dark: #000000 !default;
$kp-mono-secondary: #b0bfc8 !default;
$kp-mono-secondary-light: #e2f2fb !default;
$kp-mono-secondary-dark: #808f97 !default;


$kp-font-primary: #00084d !default;


//Siba_Web定義
$white: #ffffff !default;
$background-white: #FAFAFA !default;
$background-blue-gray-transparent: rgba(178, 202, 211,0.5) !default;

$primary-color : $kp-primary-dark !default;
$light-color : $kp-mono-secondary !default;
$accent-white-color : $background-white !default;


$white-color: white !default;

$divider: rgba(217, 217, 217, 0.25) !default;//#d9d9d9

$text-color-header: $kp-font-primary !default;
$text-color: $kp-mono-primary !default;
$text-color-dark: rgba(0, 0, 0, 0.65) !default;

$body-bg: $accent-white-color !default;
$header-bg: $body-bg;
$footer-bg: $kp-mono-primary;
$footer-font-color: white !default;
$questionnaire-form-bg: $background-blue-gray-transparent !default;


//tag
$tag-option: #79c15a;
$tag-required: yellow;
$tag-answered: white;
$tag-unanswered: purple;
$tag-emergency: red;
$tag-important: purple;
$tag-normal: #108ee9;
$tag-blue-gray: $kp-primary-dark;
$tag-gray: $kp-mono-secondary-dark;
$tag-gray2: #ccc;


$gutter: 20px !default;
$gutter-small:  calc($gutter / 2) !default;
$gutter-large: $gutter * 2 !default;


//media

// 関西大学仕様用ヘッダーロゴ追加
#content-header {
  background-image: none; //url('../images/skin/ru/header-logo.png') 形式で与えるのが正
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    background-image: none;
  }
  background-repeat: no-repeat;
  background-position: right 15px top 0px;
  background-size: 200px 100px;
  @media only screen and (max-width:1020px)  {
    background: none;
  }
}
