@import './_variables.scss';

.title-wrapper {
  margin-bottom: 20px;

}

.group-tree-container-title-wrapper {
  display: flex;
  flex-direction: row;
  h2,.title-with-description {
    flex-grow: 1;
  }
  h3 {
    margin-right: 20px;
    align-self: center;
  }
}