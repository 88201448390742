@import './variables';


.module-questionnaire {

}


.module-questionnaire-single {

  .ant-modal-body {
    padding-top:0;
  }

  .module-questionnaire-title {
    .title {
      margin: 10px 0;
      font-size:16px;
    }
  }
  .meta {
    font-size: 1.1em;
    padding:10px 20px 10px 20px;
    color: #555555;
    background-color: #F8F8F8;
    .date ,.category, .publish-from {
      margin:3px 0;
    }

  }

  .questionnaire-preview {
    font-size: 1.1em;
    line-height:180%;
  }

  .last-modified {
    margin-top:30px;
    margin-bottom:-15px;
    color: #777777;
    text-align: right;
  }

  .question-form-questionnaire-header {
    margin-bottom: $gutter-small
  }

  .question-modal-header {
    margin-bottom: $gutter
  }
}
