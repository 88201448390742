@import './variables';


.common-modal {
  width: 80vw !important;
  @media screen and (max-width: 1239px) {
    top: 5px !important;
    width: 97vw !important;
  }
  @media screen and (max-width: 1400px) {
    top: 5px !important;
    width: 97vw !important;
  }
  .ant-modal-header {
    background-color: $kp-font-primary;
    .ant-modal-title {
      color: $white-color
    }
  }
  .ant-modal-body {
    padding: $gutter-small 0
  }

  @media print {
    width:calc(100vw - 15px) !important;
    top:0 !important;

    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-content {
      box-shadow: none;
    }

  }

}


.common-modal-small {
  @extend .common-modal;
  width: 40vw !important;
}


.layout-settings-modal {
  width: 97vw !important;
  @media screen and (max-width: 1239px) {
    min-width: 97vw !important;
  }

}


