@import './variables';


.module-notification-single {

  z-index: 9999;

  .ant-modal-body {
    padding-top:0 !important;
  }

  .module-notification-single-title {
    .title {
      font-size:16px;
    }
  }
  .meta {
    font-size: 1.1em;
    padding:10px 20px 10px 20px;
    color: #555555;
    background-color: #F8F8F8;
     .date ,.category, .publish-from {
       margin:3px 0;
     }

  }

  .notification-preview {
    font-size: 1.1em;
    line-height:180%;
  }

  .last-modified {
    margin-top:30px;
    margin-bottom:-15px;
    color: #777777;
    text-align: right;
  }
}
