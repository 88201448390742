@import '_variables.scss';

#content-header {
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding:  $gutter-small $gutter;
  background-color: $header-bg;
  margin-bottom: 0px;
  border-bottom: 1px solid #d9d9d9;

  h1{
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
    color: $text-color-header;
  }
}
