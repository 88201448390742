@import '_variables.scss';

#menu {
  min-width: 250px;
  width: 250px;
  background-color: $kp-primary-dark;

  .header-logo {
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    padding: $gutter;
    border-bottom: 0.5px solid $divider;
    font-size: 0.95em;

    .account{
      display: flex;
      padding: $gutter-small 0 ;

      #account-image{
        width: calc(2em * 1.5); // 2行 x line-height
        background-repeat: no-repeat;
        background-clip: padding-box;
        background-size: contain;
      }

      p{
        margin-left: 0px;
        line-height: 1.5;
      }
    }

    button{
      margin: 5px 0;
      padding: 5px;
      border: 0;
    }

    #quit-operation{
      color: black;
      background-color: white;
    }
    #logout{
      color: white;
      background-color: black;
    }
  }

  li.ant-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}
