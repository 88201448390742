@import '_variables.scss';

#app {
  display: flex;
  #app-contents {
    flex-grow: 1;
    //1024から250px(menu)引いた値
    min-width: 774px;
    //footer分を削除した値
    min-height: calc(100vh - 105px);
    //右メニューコンテナ
    .container {
      display: flex;
      flex-direction: column;
      //コンテナ内タブ要素
      .content-tab {
        //最小サイズは画面サイズから ContentHeaderとFooterを除いたサイズ
        //contentHeader 130px と margin-bottomの10px
        //Footer 105px
        min-height: calc(100vh - 245px );
      }
    }
  }
}

//カードデザイン用 box-shadow
.card {
  padding: $gutter;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
}

//aタグ
a.link-box-a {
  color: $kp-mono-primary;
  &:hover {
    color: $kp-secondary-dark;
  }
}

.buttonLink {
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  background-color: $kp-secondary-dark;
  border-color: #f50000;
  &:hover {
    opacity: 0.6;
    color: white;
  }
}

//汎用検索フォーム
.general-search {
  margin-bottom: $gutter;
  .ant-form-item {
    margin-bottom: $gutter-small;
  }
  .ant-radio-group {
    margin-bottom: $gutter-small;
  }
  h2 {
    padding-bottom: $gutter-small;
  }
}
.title-container{
  justify-content: flex-start;
  flex-grow: 1

}
//ボタン横並wrapper
.button-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-btn {
    min-width: 80px;
    margin-left: $gutter;
  }
  .text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      justify-items: center;
      top: 50%;
    }
  }
  .button-container-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .button-container-left {
    display: flex;
    flex-grow: 1
  }
}
.submit-container{
  width : 100%;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    min-width: 80px;
    margin-left: $gutter;
  }
  span {
    cursor: pointer;
  }
}


.column-container {
  width: 100%;
  padding:  $gutter-small $gutter;
  display: flex;
  .right {
    margin-top: $gutter;
  }
  .left {
    flex-grow: 1;
    margin-top: $gutter;
    margin-right: $gutter;
  }
}

//汎用tab内container
.tab-container {
  width: 100%;
  padding:  $gutter-small $gutter;
}

//汎用テーブル
.general-table {
  tr {
    background: $white-color;
  }
  tr:nth-child(even){
    background: $accent-white-color;
  }
}


//汎用ラッパー
.wrapper {
  margin: $gutter
}

//テーブル上部のWrapper
.table-header-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $gutter-small;
  h2 {
    flex-grow: 1;
  }
}

//タグ
.publish-from-wrapper {
  font-size: 1.0em;
  color: $kp-mono-primary;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
}

//汎用タグ
.tags-wrapper {
  text-align: left;
  display: flex;
  align-items: left;
  margin-left: 5px;
}
.tag {
  margin:0 5px 0 0px;
  padding: 1px 10px;
  display: inline-block;
  text-align: center;
}

.tag.answered {
  background-color: $tag-answered;
  border: 1px solid $kp-mono-primary-light;
  color: $kp-mono-primary-light;
}
.tag.unanswered {
  background-color: $kp-primary-dark;
  color: $white;
}
.tag.option {
  background-color: $tag-option;
  color: $white;
}
.tag.required {
  background-color: red;
  color: $white;
}

.tag.emergency {
  background-color: $tag-emergency;
  color: $white;
}
.tag.important {
  background-color: $tag-important;
  color: $white;
}
.tag.normal {
  background-color: $tag-normal;
  color: $white;
}
.tag.blue-gray {
  background-color: $kp-primary-dark;
  color: $white;
}
.tag.gray {
  background-color: $tag-gray;
  color: $white;
}
.tag.gray2 {
  background-color: $tag-gray2;
  color: $white;
}


@import "ModuleSchedules.scss";