@import '_variables.scss';




.login-notification-header {

  .login-notification-header-title {
    padding-right: 16px;
    font-weight: 500;
    color: $text-color;
    font-size: 1.15em;
    line-height: 150%;
    margin-top:10px;
    margin-bottom:5px;
  }

  .login-notification-header-box {
    display: flex;
    align-items: center;

    .login-notification-header-date {
      font-weight: 700;
      font-size: 1em;
      color: $text-color-header;
    }

    .login-notification-header-tags {
      text-align:left;

      display: flex;
      align-items: left;
      .login-notification-header-tags-warm,
      .login-notification-header-tags-emergency {
        margin:0px 5px 0px 10px;
        width: 50px;
        background-color: #f50000;
        color: #FFFFFF;
        text-align:center;
      }
    }
  }

  .login-notification-header-from {
    color: #666666;
  }

}

.login-notification-body {
  .login-notification-body-content {
    margin-bottom: 20px;
    padding-left: 15px;
    line-height: 180%;
    font-size:1em;
  }
}