@import './variables';

.drag-handle {
  position: absolute;
  z-index: 6;
  height: 35px;
  width: 15px;
  right: 0;
  color: $white;
  display: flex;
  align-items: center;
  background-color: $kp-primary;
  margin: 1px 0 0 0;
  cursor: move;
  .anticon-swap {
    font-size: 18px;
  }
}

.module-card {
  height:100%;
  width: 100%;
  .ant-card-head {
    height: 36px;
    background-color: $kp-primary-dark;
    .ant-card-head-title {
      color: $white;
      padding: 8px 0;
      line-height: 20px;
      div{
        display: flex;
        justify-content: space-between;
        .module-title{
          flex-grow: 1;
        }
        .module-icon {
          width: auto;
          padding: 0 $gutter-small;
          cursor: pointer;
          .pin{
            font-size: 14px;
          }
        }
        .module-title-button {
          background-color: $white;
          border-color:transparent;
          color: $kp-primary-dark;
          line-height: 20px;
          height: 20px;
          align-self: center;
          padding: 0px 13px;
          margin-right: 10px;
          border-radius:2px;
          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }
  }
  .ant-card-body {
    //headerサイズの削除
    height: calc(100% - 48px);
    //パディングをとりあえず排除
    padding: 0;

  }
}

.module-cell-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  margin-left: 5px;
  img {
    width: 64px;
    height: auto;
  }
}



.module-cell-notification{
  cursor: pointer;
  padding: 5px;
}
.module-cell-notification-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.module-cell-notification-date-and-tag {
  display: flex;
  flex-direction: row;
  padding: 5px 0;

  .date {
    color: $kp-font-primary;
    align-self: center;
    font-weight:600;
  }
}
.module-cell-notification-title {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
}
.module-cell-notification-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.module-cell-notification-header {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  .date {
    color: $kp-font-primary;
    align-self: center;
    font-weight:600;
  }
}
.module-cell-head {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  .date {
    color: $kp-font-primary;
    align-self: center;
    font-weight:600;
  }
}

.module-cell-body {
  font-size: 14px;
}



.dynamic-sort-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 18px;
  transition: all .3s;
  margin-left: 10px;
}
.dynamic-sort-button:hover {
}
.dynamic-sort-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}