@import '_variables.scss';

.public-DraftStyleDefault-ol:before {
  display: none;
}



.notification-preview {
  //previewにのみ適用されるものとしてimportant推奨
  ul,ol {
    list-style: decimal !important;
    list-style-position: inside !important;
  }

}
.notification-preview-download {
  margin-top: $gutter;
}

.spanned-notification-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; /* 制限したい行数が3の場合 */
  overflow: hidden;
  margin:10px 0;
}