@import './_variables.scss';
@import './Font.scss';


//loader
.loader-60devs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  z-index: 10000;
  opacity: 1;
  transition: opacity 250ms 250ms;
}

.loader-60devs .loader-60devs-progress {
  height: 2px;
  width: 100%;
  background-color: $primary-color;
  transition-property: transform, opacity;
  transition-duration: 12s;
  transition-timing-function: cubic-bezier(0.04, 0.9, 0.11, 0.9);
  transform: translateX(-100%);
}

.loader-60devs[data-state="hidden"] {
  display: none;
  transition: none;
}

.loader-60devs[data-state="hidden"] .loader-60devs-progress {
  transition: none;
}

.loader-60devs[data-state="running"]::before {
  content: '';
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader-60devs[data-state="running"] .loader-60devs-progress {
  transform: translateX(0);
}

.loader-60devs[data-state="finishing"] {
  opacity: 0;
}

.loader-60devs[data-state="finishing"] .loader-60devs-progress {
  transform: scaleX(100) translateX(0);
}

//frのリンクポップアップがantDesignモーダルのz-indexに干渉している嫌いがあるのでひとまず
.fr-popup {
  z-index: 300000000000 !important;
}



body {
  margin: 0;
  padding: 0;
  background-color: $body-bg;
  color: $text-color;
  //font-family: "Noto Sans JP",sans-serif;
  //font-family:  ヒラギノ角ゴ Pro W3”,“Hiragino Kaku Gothic Pro”,“メイリオ“,”Meiryo”,sans-serif;
  font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  height: 100%;
  font-size: 14px;
  -ms-touch-action: pan-x pan-y ;
  touch-action: pan-x pan-y;
  min-width: 1024px;
}


h2 {
  color: $text-color;
  margin-bottom:15px;
}

h3 {
  color: $text-color;
  margin-bottom:10px;
}

// アンケートなどのプレーンテキストへ改行を描画させる
.plain-text {
  white-space: pre-line;
}

@import './AntDesignCustom.scss';


@import './FroalaEditorTheme.scss';

//共通ホーム
@import "./App.scss";
//ログイン画面
@import './Login.scss';

//Footer
@import "./Footer.scss";
//ログイン画面（ゲスト用お知らせリスト）
@import './ListAdapterLogin.scss';

//左メニュー
@import "./Menu.scss";
//右コンテンツ共通ヘッダ
@import './ContentHeader.scss';

//管理画面ホーム
@import './AdminHome.scss';

//お知らせ
@import './Notification.scss';
//アンケート
@import './Questionnaire.scss';
//学年暦
@import './Calendar.scss';

//対象者選択
@import './SelectTargetForm.scss';

// 共通モーダル
@import './ModalCommon.scss';
// 共通モジュール
@import './Module.scss';

// ユーザお知らせモジュール
@import "./ModuleNotification.scss";

// ユーザお知らせモーダル
@import './ModuleNotificationModal.scss';

// ユーザお知らせコンテンツ表示モーダル
@import './NotificationModalSingle.scss';

// ユーザお知らせ配信状況モーダル
@import './NotificationStatusModal.scss';

// ユーザアンケートモジュール
@import './ModuleQuestionnaire.scss';

// ユーザ管理
@import "./Users.scss";

// NotFound
@import "./NotFound.scss";
