.rst__rowLabel {
  width: 100%;
  padding-right: 0;
}

.rst__rowTitle {
  div {
    display: flex;
    span.title{
      flex-grow:1;
    }
    span {
      align-self: center;
    }
  }
}