@import './variables';
@import './ModalCommon.scss';

.notification-status-modal {

  top:10px;

  .ant-modal-body {
    padding-top:0;
  }

  .notification-status-modal-card-wrapper {
    background-color: #F0F0F0;
    padding: 10px;
    margin-bottom:10px;

    .notification-status-modal-card {
      height: 200px;
    }

    .ant-progress-circle-path {
      stroke: #108ee9;
    }

    .notification-status-modal-title {
      font-size: 1.1em;
    }

    .notification-status-modal-value {
      font-size:1.5em;
      margin-bottom:10px;
    }
  }



}