@import '_variables.scss';



// antd 丸いボタン
.ant-btn-circle {
  // 関西用radius復活
  border-radius: 50% !important;
}
//グローバルメニュー（左側メニュー　カスタム
.ant-menu-item {
  //border-bottom: 0.5px solid $divider;
  span {
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1em;
    font-weight: 300;
  }

  span:hover {
    transition: color .5s;
    color: rgba(255, 255, 255, 1);
  }

  min-height:48px;
}

.ant-menu-item-selected {
  span {
    color: rgba(255, 255, 255, 1);
  }

}

//antd tab
.ant-tabs-bar {
  //タブメニューの下のマージンを排除
  margin-top: 0;
  margin-bottom: 0;
  // background-color: #e0e7eb;
}

.ant-tabs-nav-wrap {
  padding-left: 20px;
}

.ant-tag {
  border-radius: 0px;
  border: 0px;
  margin: 0px;
}

//formラベル左寄せ
.ant-form-item-label {
  text-align: left;
  label:after {
    //labelの右側の ":" をデリートするため上書き
    content: "";
    margin: 0;
  }
}

//メインメニュータブサイズ
.ant-tabs-nav-container{
  line-height: 2.5;
  //background-color: lightgray;
}

// アップロードドラッグ部分
.ant-upload.ant-upload-drag {
  padding: $gutter-small;
  background-color: $accent-white-color;
}

// ラジオタブ
.ant-radio-button-wrapper {
  min-width: 130px;
  text-align: center;
}

// モーダルの閉じるボタン
.ant-modal-close-x {
  color: #FFFFFF;
}



//ant-card-wider-paddingへの対応
.module-card.ant-card-wider-padding {
  .ant-card-head {
    padding: 0 25px;
  }
  .ant-card-body {
    padding: 0;
  }
}

.ant-select-dropdown-menu-item {
  .select-title {
    margin-right: 20px;
  }
  .select-description {
    color: #888888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-select-selection-selected-value {
  .select-description {
    display: none;
  }
}
.ant-select-dropdown-menu-item-selected {
  .select-description {
    font-weight: normal;
  }
}

//ant-collapseへの対応(ニュースアコーディオン表示)
.ant-collapse-item.login-notification-body {
  background-color: $background-white;
}
.ant-collapse-item.login-notification-body:nth-child(odd) {
  background-color: #e5e9ea;
}
.ant-collapse-content {
  background-color: transparent;
}

.ant-badge-status-processing {
   background-color:#108ee9;
   border: 1px solid #108ee9;
 }

.ant-badge-status-processing::after {
  background-color:#108ee9;
  border: 1px solid #108ee9;
}

.ant-progress-status-success .ant-progress-text{
  color: #108ee9;
}

.ant-legacy-form label {
  font-size: 12px;
}

.ant-legacy-form-extra {
  font-size: 12px;
}

.ant-legacy-form-item {
  margin-bottom: $gutter-small;
}

  //formラベル左寄せ
.ant-legacy-form-item-label {
  padding-left: 10px;
  text-align: left;
  margin-bottom: 0px;
  label:after {
    //labelの右側の ":" をデリートするため上書き
    content: "";
    margin: 0;
  }
}

@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-dropdown,
  .ant-select-dropdown,
  .ant-picker-dropdown {
    animation-duration: 0s !important;
  }
}
.common-modal:after{
  content: '';
  position: fixed;
  display: block;
}

.ant-checkbox-group {
  width: 100%
}

