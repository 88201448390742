//* ===============Login設定=====================
@import '_variables.scss';


.login-body {
   min-width: 320px;

  #content-header {
    @media screen and (max-width: 1023px) {
      background-image: none;
    }
  }

  #login {
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 1023px) {
      height: 100%;
    }

    #login-body {
      display: flex;
      height: calc(100vh - 100px - 130px);
      background-color: $body-bg;
      @media screen and (max-width: 1023px) {
        flex-flow: column-reverse;
        height: 100%;
      }
      #login-left {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-white;
        @media screen and (max-width: 1023px) {
          min-height: 100px;
        }
      }
      #login-right {
        width: 440px;
        overflow: auto;
        overflow-x: hidden;
        background-color: $kp-primary-dark;
        @media screen and (max-width: 1023px) {
          width: 100%;
        }
      }
    }

    .login-form {
      width: 400px;
      margin: $gutter auto;
      padding-bottom:10px;
      position: relative;
      @media screen and (max-width: 1023px) {
        width: 94%;
        margin-bottom: $gutter;
      }
      .ant-input {
        //iOSのinputズーム回避の為暫定対応
        font-size: 16px;
      }
      .login-form-advice {
        float: right;
      }
      .login-form-button {
        width: 100%;
        height: 40px;
        margin-top: 10px;
      }
      .top-logo {
        width: 340px;
        height: 62px;
        margin: 0 10px 10px 10px;
        background-repeat: no-repeat;
        background-size: 250px 50px;
        background-position: center center;
      }
      h4 {
        margin: 0;
        color: #72777c;
      }
      .remember-box {
        display: flex;
        margin-top: 20px;
        input {
          width: 16px;
          height: 16px;
          margin: 0 10px 20px 0;
        }
      }
      .login-button {
        display: flex;
        justify-content: center;
        button {
          background-color: #0085ba !important;
          color: #fff;
        }
      }
    }

    .ad-form {
      width: 400px;
      margin-top: $gutter;
      margin-left:auto;
      margin-right: auto;
      padding:0px;
      position: relative;
    }


    .collapse_panel_wrapper {
      width: 100%;
      border: none;
    }


  }


  .login-password-forget-modal {
    p {
      margin: 20px auto;
    }
    ul {
      margin: 20px auto;
    }
  }


  footer {
    @media screen and (max-width: 1023px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: $gutter;
    }
    .footer-logo,.footer-main,.footer-copyright {
      @media screen and (max-width: 1023px) {
        margin: 0 0 10px 0;
      }
    }
    .footer-copyright {
      @media screen and (max-width: 1023px) {
        text-align: left;
        align-self: flex-start;
      }
    }
  }

}
